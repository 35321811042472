/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetServerSideProps } from 'next';

import { PageRendererComponent, getProcessEnvs, prePageCall, canUseDOM } from '@marriott/mi-headless-utils';
import operationSignatures from '../_constants/operationSignatures.json';

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

function pageRenderer(props) {
  return <PageRendererComponent {...props} />;
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const homepageSpecificData = async () => {
  const {
    NEXT_PUBLIC_SUBMIT_SEARCH_URL,
    NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
    GOOGLE_MAP_API_KEY,
    BAIDU_MAP_API_KEY = null,
    UXL_IMAGE_CACHE_DOMAIN = null,
    SESSION_GET_CALL_URL_CLIENT,
    SESSION_APP_GET_CALL_V1_URL,
    GET_USER_DETAILS,
    DEPLOYED_ENV_CONFIG,
    TARGET_PROPERTY_TOKEN,
    NEXT_PUBLIC_FETCH_OFFERS_API_URL,
    SESSION_APP_POST_CALL_URL,
  } = getProcessEnvs();

  return {
    operationSignatures,
    productSpecificResponse: [],
    NEXT_PUBLIC_SUBMIT_SEARCH_URL,
    NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
    NEXT_PUBLIC_FETCH_OFFERS_API_URL,
    TARGET_PROPERTY_TOKEN,
    GOOGLE_MAP_API_KEY,
    BAIDU_MAP_API_KEY,
    UXL_IMAGE_CACHE_DOMAIN,
    SESSION_GET_CALL_URL_CLIENT,
    SESSION_APP_GET_CALL_V1_URL,
    SESSION_APP_POST_CALL_URL,
    GET_USER_DETAILS,
    DEPLOYED_ENV_CONFIG,
    buildInfo: {
      deployedEnvType: process.env.DEPLOYED_ENV_TYPE ?? null,
      deployedEnvConfig: process.env.DEPLOYED_ENV_CONFIG ?? null,
      jenkinsDeploymentBuild: process.env.JENKINS_DEPLOYMENT_BUILD ?? null,
    },
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
export const getServerSideProps: GetServerSideProps<any> = prePageCall(homepageSpecificData);
export default pageRenderer;
